$top-nav-height: 90px;
$tab-border-width: 4px;
$active-tab-color: #006BD6;

.topNav {
  grid-column: 2 / 6;
  grid-row: 1 / 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0 15px 50px 20px rgba(0, 0, 0, 0.05);
  z-index: 1;

  .tabItem {
    height: 100%;
    margin: 0 5rem;
    cursor: pointer;
    font-family: 'TT Norms Pro Bold';
    line-height: 2.4rem;
    letter-spacing: 1.5px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6e7479;
    border-bottom: $tab-border-width solid transparent;
    z-index: 1;

    h2 {
      font-size: 2.5rem;
      text-transform: capitalize;
    }

    &.activeTab {
      color: $active-tab-color;
      border-bottom: $tab-border-width $active-tab-color solid;
      transition: border .2s ease-in;
    }
  }

  .emailBtn {
    width: 18rem;
    height: 58%;
    margin-left: auto;
    margin-right: 2rem;
    font-size: 1.9rem;
    font-family: 'TT Norms Pro Bold';
    letter-spacing: 1px;
    background-color: #006bd6;
    border-radius: .5rem;
  }
}
