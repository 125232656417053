@import 'variable.scss';
@import 'mixins.scss';

* {
  box-sizing: border-box;
}

body {
  font-family: 'TT Norms Pro Regular';
  line-height: normal;
  background-color: #fff;
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  font-family: 'TT Norms Pro Regular';
  border: none;
  background-color: $secondary;
  display: inline-block;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  color: $white;
  transition: all 0.4s ease;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: $primary;
  }
}

h2 {
  margin: 0;
  font-weight: normal;
}

h4 {
  color: $primary;
  margin: 0;
  letter-spacing: 0.3px;
}

.center-align {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  position: absolute;
  width: auto;
  height: auto;
}
