@import '../../styles/mixins.scss';

.product {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease;

  img {
    box-sizing: content-box;
    width: 5rem;
    height: 5rem;
    padding: 0.5rem;
    border: 1px solid $gray;
    border-radius: 0.3rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

    &.active {
      border-color: $primary;
    }
  }

  h3 {
    margin: 1rem;
    font-size: 1.7rem;
  }
}
