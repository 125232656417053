.videoPlayerOverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.9);
  z-index: 2;
}

.videoPlayerContainer {
  width: 95%;
}

// JW Player has its own inherent styling so videoPlayerContainer can't be flex - need closeBtnContainer
.closeBtnContainer {
  width: 100%;
  padding: 3rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.closeBtn {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}