.subcategoryNav {
	width: 50%;
	height: 10%;
	margin: 0 auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	bottom: 14rem;
}