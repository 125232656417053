.emailCaptureOverlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
}

.emailBanner {
  height: 50%;
  padding-top: 10rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.closeBtn {
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 3rem;
  right: 3rem;
  filter: invert(100%); // TEMPORARY FIX UNTIL DESIGNERS SENT CORRECT COLOR - CHANGE THIS
  cursor: pointer;
}

.emailCaptureSubheading {
  font-size: 1.4rem;
  font-family: 'TT Norms Pro Medium';
  // letter-spacing: 0.3rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.emailCaptureHeading {
  margin: 1.5rem 0 3rem 0;
  font-size: 3.6rem;
  // font-family: 'TT Norms Pro Bold';
  font-family: 'TT Norms Pro Medium';
  // letter-spacing: 0.1rem;
}

/* -------------------------------------------------------------------------- */
/*                                    Form                                    */
/* -------------------------------------------------------------------------- */

.emailForm {
  width: 43%;
  display: flex;
  flex-wrap: wrap;
}

.emailInput {
  width: 69%;
  padding: 1.5rem 2rem;
  font-size: 2rem;
  font-family: 'TT Norms Pro Medium';
  border: solid black;
  // border-width: 0.1rem 0 0.1rem 0.1rem;
  border-width: 1px 0 1px 1px;
  // border-width: 2px 0 2px 2px;
  // border-radius: 0.4rem 0 0 0.4rem;
  border-radius: 0.5rem 0 0 0.5rem;
}

.emailSubmitBtn {
  width: 31%;
  // text-align: center;
  font-size: 1.5rem;
  font-family: 'TT Norms Pro Bold';
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  background-color: rgb(0, 107, 214);
  border: solid black;
  // border-width: 0.1rem 0.1rem 0.1rem 0;
  border-width: 1px 1px 1px 0;
  // border-width: 2px 2px 2px 0;
  // border-radius: 0 0.4rem 0.4rem 0;
  border-radius: 0 0.5rem 0.5rem 0;
}

.emailError {
  font-size: 2rem;
  font-family: 'TT Norms Pro Regular';
  color: rgb(255, 66, 66);
}