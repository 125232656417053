@import '../../../styles/variable.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.imageContainer {
  align-self: center;
  text-align: center;
}

.image {
  height: 18rem;
}

.headerSection {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
}

// Null sale titles are hidden with JSX to ensure horizontal product title alignment across cards
.saleTitle {
  margin-bottom: 1.5rem;
  padding: 0.5rem 0.7rem;
  display: inline-flex;
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  border: 2px solid #006bd6;
}

.title {
  margin: 0;
  font-size: 3.2rem;
  line-height: 4.8rem;
  font-family: 'TT Norms Pro Medium';
}

.price {
  margin: 0;
  font-size: 2.5rem;
}

.originalPrice {
  margin-right: 1rem;
  color: #A7A7A9;
  text-decoration: line-through;
}

.salePrice {
  color: #036BD6;
}

.colorDotsContainer {
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}

.colorDot {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 1px solid #b2b2b2;
}

.featureTitle {
  margin: 1rem 0;
  font-size: 1.4rem;
  color: $light;
  font-family: 'TT Norms Pro Medium';
  letter-spacing: 1pt;
}

.features {
  margin: 0;
  padding-left: 1.7rem;
  color: $dark;

  li {
    margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}