@import '../../styles/variable.scss';

.homePageContainer {
	grid-column: 1 / 6;
	grid-row: 1 / 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: black;
}

.homeBackgroundVid {
	position: fixed;
	opacity: 0.5;
}

.therabodyLogo {
	width: 30%;
	margin-bottom: 6rem;
	z-index: 1;
}

.getStartedLink {
	width: 15%;
	padding: 2rem 1rem;
	font-size: 1.5rem;
	z-index: 1;
	font-family: 'TT Norms Pro Medium';
	text-align: center;
	color: white;
	background-color: #006bd6;
	letter-spacing: 0.2rem;
	text-decoration: none;
	border-radius: 3px;
	outline: none;
}