@import '../../styles/variable.scss';

.productType {
  border: 2px solid $gray;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 1rem;
  background-color: $white;
  cursor: pointer;
  transition: all 0.4s ease;
  width: 80.5%;
  height: 21.8%;

  &.active {
    border-color: $primary;
  }

  &.isDifferentOpacity {
    opacity: 0.7;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  img {
    height: 63.5%;
    margin: 0;
  }
  h3 {
    margin: 0;
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}
