html {
  /* font-size: 62.5%; */
  /* Specifying root element with vw allows px to be converted to responsive rem unit */
  /* Divide units by 10 for conversion e.g. 16px => 1.6rem */
  font-size: .735vw;
}

html, body, #root, .App {
  touch-action: none;
}

.App {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(10, 1fr);
  overflow: hidden;
}

.App-page {
  grid-column: 2 / 6;
  grid-row: 2 / 11;
}

::-webkit-scrollbar {
  display: none;
}

/* Enforcing horizontal lock on IPad */
@media screen and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}