//== Colors
$white: #fff;
$black: #000;
$primary: #006bd6;
$secondary: #0051a3;
$gray: #dbdbdb;
$dark: #2d2d2d;
$dark-gray: #747476;
$light: #6e7479;
$light-gray: #f4f4f5;
$text: #252729;
