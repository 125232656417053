@import '../../styles/variable.scss';

// All properties are same as ProductTypeCard module except where stated otherwise
.videoCategoryCard {
	border: 2px solid $gray;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 2rem 1rem; // Different
	background-color: $white;
	cursor: pointer;
	transition: all 0.4s ease;
	width: 80.5%;
	height: 21.8%;
  
	&.active {
	  border-color: $primary;
	}
  
	&.isDifferentOpacity {
	  opacity: 0.7;
  
	  &:hover,
	  &.active {
		opacity: 1;
	  }
	}
}

.videoCategoryImg {
	width: 50%;
}

.videoCategoryTitle {
	margin: 0;
	font-size: 2rem;
}