.sidebarContainer {
  grid-column: 1 / 2;
  grid-row: 1 / 11;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(244, 244, 245);
  text-align: center;
  font-family: 'TT Norms Pro Medium';
  font-size: 20px;
  z-index: 2;
}

.backBtn {
  width: 78%;
  margin-top: 5rem;
  margin-bottom: 40px;
  height: 8rem;
  font-size: 1.8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 3rem;
  }

  h3 {
    margin: 0;
  }
}

.productModels {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}