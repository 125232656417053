@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

$drawer-tab-width: 23.4rem;
$drawer-tab-height: 8.3rem;

.root {
  width: 100%;
  // Shifts the overlay to the right while leaving the drawer tab and soon-to-be-gray underlay exposed.
  left: calc(100% - #{$drawer-tab-width});
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
}

.container {
  display: flex;
  align-items: center;
  height: 100vh;
  z-index: 3;
}

.tab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: $drawer-tab-width;
  height: $drawer-tab-height;

  // Drawer tab width determines width of gray background area. The width will vary depending on the device.
  // Once the tab is rotated 90deg, it will be bisected by the midpoint of the width of the gray area.
  // In order to shift the tab to the right and "attach it" to the overlay, translateY is employed.
  // (tabWidth/2) - (tabHeight/2) will align the tab to the right, and the extra 1rem is an arbitrary value
  // that "hides" a bit of the tab under the overlay.
  transform: rotate(-90deg) translateY(($drawer-tab-width / 2) - ($drawer-tab-height / 2)) + 1rem;
  -webkit-transform: rotate(-90deg) translateY(($drawer-tab-width / 2) - ($drawer-tab-height / 2) + 1rem);

  border-radius: 0.7rem;
  background: $white;
  box-shadow: 0px -2px 18px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.tabLine {
  margin-top: .5rem;
  width: 6.5rem;
  border-style: solid;
  border-color: rgb(216, 216, 216);
  border-width: 0.4rem 0 0 0;
  border-radius: 0.1rem;
  transform: rotate(0deg);
  margin: .8rem auto 0 auto;
  transition: all 0.4s ease;
}

.tabText {
  font-family: 'TT Norms Pro Medium';
  font-size: 2rem;
  margin-top: 1.4rem;
  text-align: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $white;
}

.contentContainer {
  display: flex;
}

.descriptionContainer {
  width: 40%;
  text-align: start;
  padding: 3% 3% 3% 5%;
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachmentSection {
  border-top: 1px solid rgb(244, 244, 245);
  margin-top: 3.2rem;
}


.title {
  font-size: 4rem;
  margin: 1.2rem 0;
  font-family: 'TT Norms Pro Regular';
}

.description {
  color: $dark-gray;
  margin: 0 0 1.5rem 0;
  font-size: 1.6rem;
  letter-spacing: .05rem;
  line-height: 2.4rem;
}

.list {
  font-family: 'TT Norms Pro Medium';
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
  letter-spacing: 0.011rem;
  margin-top: 2rem;
  color: $text;

  li {
    padding: .6rem 0;
  }
}

.table {
  display: flex;
  justify-content: space-between;

  table {
    margin: 1rem 0;
  }

  td {
    padding: .5rem 0;
    font-size: 1.6rem;
  }

  .attachmentTitle {
    vertical-align: top;
  }

  .attachmentDescription {
    // padding-left: 27px;
    // rem unit division (see App.css) leads to line break - rounding down from 2.7 to 2.65
    padding-left: 2.65rem;
    color: rgb(116, 116, 118);
    letter-spacing: 0.025rem;
  }
}




.slideLeft {
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include animation('fadeIn 0.3s forwards');
    animation-delay: 0.4s;
  }
}
@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    background-color: #3f3f3f;
  }
}

@include keyframes(slideLeft) {
  from {
    left: calc(100% - #{$drawer-tab-width});
  }

  to {
    left: 0;
  }
}

.slideLeft {
  @include animation('slideLeft .5s forwards');
}

@include keyframes(slideRight) {
  from {
    left: 0;
  }

  to {
    left: calc(100% - #{$drawer-tab-width});
  }
}

.slideRight {
  @include animation('slideRight .5s forwards');
}

.imageContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100vh;
  min-height: 630px;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  background-color: #f4f4f6;
}

.image {
  width: 1;
}
