.comparePage {
  display: flex;
  justify-content: space-between;
  padding: 3.2rem;
  height: 100%;

  > div {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 2rem;

    &:not(:last-child) {
      margin-right: 3.2rem;
    }
  }

  &.items_2 {
    > div:not(:last-child) {
      padding: 0 3.2rem;
    }
  }
  &.items_4 {
    > div:not(:last-child) {
      margin-right: 2.4rem;
    }
  }
}