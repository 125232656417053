.howToContainer {
	height: 100%;
	padding: 4rem 5rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-auto-flow: column;
	gap: 4rem;
	overflow: scroll;
	touch-action: pan-x;

	.videoBox {
		width: 43.5rem;
		height: 32.4rem;
		cursor: pointer;

		.vidThumbnailContainer {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
			.playBtn {
				width: 20%;
				z-index: 1;
			}
			.vidThumbnail {
				width: 30rem;
			}
		}
	
		h4 {
			margin-top: 2rem;
			font-size: 2.2rem;
			color: black;
		}
	}

	// Doing this hack because howToContainer right padding doesn't work on Safari for some reason
	.videoBox:last-child {
		box-sizing: content-box;
		padding-right: 5rem;
	}
}